var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "a-col",
            { attrs: { md: 2, sm: 4 } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "90px" },
                  attrs: { defaultValue: "POST", size: "large" },
                  on: { change: _vm.handleChange },
                },
                [
                  _c("a-select-option", { attrs: { value: "POST" } }, [
                    _vm._v("POST"),
                  ]),
                  _c("a-select-option", { attrs: { value: "GET" } }, [
                    _vm._v("GET"),
                  ]),
                  _c("a-select-option", { attrs: { value: "PUT" } }, [
                    _vm._v("PUT"),
                  ]),
                  _c("a-select-option", { attrs: { value: "DELETE" } }, [
                    _vm._v("DELETE"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { md: 22, sm: 20 } },
            [
              _c("a-input-search", {
                attrs: {
                  placeholder: "input send url",
                  enterButton: "Send",
                  size: "large",
                },
                on: { search: _vm.onSearch },
                model: {
                  value: _vm.url,
                  callback: function ($$v) {
                    _vm.url = $$v
                  },
                  expression: "url",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-tabs",
        { attrs: { defaultActiveKey: "2" } },
        [
          _c("a-tab-pane", { key: "2", attrs: { tab: "params" } }, [
            _c("textarea", {
              staticStyle: {
                width: "100%",
                "font-size": "16px",
                "font-weight": "500",
              },
              attrs: { rows: 13 },
              on: { blur: _vm.changeVal },
            }),
          ]),
        ],
        1
      ),
      _c(
        "a-tabs",
        { attrs: { defaultActiveKey: "1" } },
        [
          _c("a-tab-pane", { key: "1", attrs: { tab: "response" } }, [
            _c("textarea", {
              staticStyle: {
                width: "100%",
                "font-size": "16px",
                "font-weight": "500",
              },
              attrs: { rows: 10, readOnly: "" },
              domProps: { innerHTML: _vm._s(_vm.resultJson) },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }